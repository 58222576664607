import React from "react";
import { graphql } from "gatsby";
import {Container, Heading, SimpleGrid} from "@chakra-ui/react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicFinancialEducation from "../../hooks/usePrismicFinancialEducation";
import YoutubeFrames from "../../components/Youtube";

const InformationVideos = () => {
    const { language } = useTranslation();
    const data = usePrismicFinancialEducation()
    const videos = data.links.concat(data.links_1)

    return (
        <Main>
            <Container maxW="7xl" mb="13.5rem">
                <Seo
                    title={data.title}
                    description={data.title}
                    link={`https://www.fsm.am/${language}/{t("news")}`}
                />
                <SmallNav>

                    <Link to="/financial-education">{data.title}</Link>

                </SmallNav>
                <Heading
                    fontFamily="Mardoto"
                    as="h2"
                    fontSize={{ base: "2xl", md: "3xl" }}
                    mt="1.25rem"
                    mb="3.81rem"
                >
                    {data.title}
                </Heading>
                <SimpleGrid columns={{ base: "1", md: "3" }} spacing={2}>
                    {videos?.map((slide, idx) => (
                        <React.Fragment key={`slide-${slide.title}-${idx}`} style={{ width: "100%", height: "100%" }}>
                            <YoutubeFrames
                                idx={idx}
                                src={slide.src}
                                title={slide.title}
                                thumbnail={slide.thumbnail}
                            />
                        </React.Fragment>
                    ))}
                </SimpleGrid>

            </Container>
        </Main>
    );
};

export default InformationVideos;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
